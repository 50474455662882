import React from "react"
import { Link } from "gatsby"
import "./menu.css"
import { Nav, Dropdown, NavItem } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

export default () => (
  <div className="navigation">
    <ul className="list-unstyled d-block mb-0">
      <li>
        {" "}
        <Link to="/">
          <StaticImage
            src="../../images/logo.png"
            placeholder="blurred"
            className=" mb-5"
            width={188}
          />
        </Link>
      </li>
      <li>
        <div className="text-white mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="me-3"
            width={30}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mx-3"
            width={30}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </div>
      </li>
      <li className="align-self-center">
        <Link to="/">Home</Link>
      </li>
      <li className="align-self-center">
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={Nav.Link}>
            {" "}
            <Link to="#about-us">Altitude Packages</Link>
          </Dropdown.Toggle>

          <Dropdown.Menu className="d-flex flex-column">
            <Dropdown.Item>
              <Link to="/what-we-do">Lorem</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      <li className="align-self-center">
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={Nav.Link}>
            {" "}
            <Link to="/rentals">Rentals</Link>
          </Dropdown.Toggle>

          <Dropdown.Menu className="d-flex flex-column">
            <Dropdown.Item>
              <Link to="/what-we-do">Lorem</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      <li className="align-self-center">
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={Nav.Link}>
            {" "}
            <Link to="#about-us">Chambers & Rooms</Link>
          </Dropdown.Toggle>

          <Dropdown.Menu className="d-flex flex-column">
            <Dropdown.Item>
              <Link to="/what-we-do">Lorem</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>

      <li className="align-self-center">
        <Link to="/get-involved">Customize Equipment</Link>
      </li>

      <li className="align-self-center">
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={Nav.Link}>
            {" "}
            <Link to="#about-us">Applications</Link>
          </Dropdown.Toggle>

          <Dropdown.Menu className="d-flex flex-column">
            <Dropdown.Item>
              <Link to="/what-we-do">Lorem</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      <li className="align-self-center">
        <Link to="/donate">Blog</Link>
      </li>
      <li className="align-self-center">
        <Link to="/donate">About</Link>
      </li>
      <li className="align-self-center">
        <Link to="/donate">Contact</Link>
      </li>
    </ul>
  </div>
)
