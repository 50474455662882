import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import { stickyWrapper, isSticky, header } from "./header.module.css"
import Sticky from "react-sticky-el"
import { StaticImage } from "gatsby-plugin-image"
import HorizontalMenu from "../header/horizontalmenu"

const Header = () => (
  <header>
    <Sticky
      className={stickyWrapper}
      stickyClassName={isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={`header ${header}`}>
        <div className="container-fluid sub-header px-5 d-lg-block d-none">
          <div className="row">
            <div className="col-md-4">
              <p>Call Us! 1-800-627-4510</p>
            </div>
            <div className="col-md-4 text-center">
              <p>
                Free Shipping!{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  className="mb-1 ms-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                  <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
                </svg>
              </p>
            </div>
            <div className="col-md-4">
              <div className="social-icons float-end">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="fa fa-facebook ms-0" />
                </a>
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-2 col-4">
              <div className="header-logo">
                <Link to="/">
                  <StaticImage
                    src="../../images/logo.png"
                    placeholder="blurred"
                    imgClassName="img-fluid"
                    width={188}
                  />
                </Link>
              </div>
            </div>
            <div className="col d-lg-inline d-none">
              <HorizontalMenu />
            </div>
            <div className="col-2">
              <div className="text-white float-end d-lg-block d-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-3"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-3"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
              <div className="text-right d-lg-none d-inline">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
