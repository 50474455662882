import React from "react"
import { Link } from "gatsby"
import { Nav, Dropdown, NavItem } from "react-bootstrap"

const HorizontalMenu = () => {
  return (
    <>
      <div className="footer-menu ">
        <div className="w-100">
          <ul className="list-unstyled d-block mb-0">
            <li className="align-self-center">
              <Link to="/">Home</Link>
            </li>
            <li className="align-self-center">
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>
                  {" "}
                  <Link to="#about-us">Altitude Packages</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d-flex flex-column">
                  <Dropdown.Item>
                    <Link to="/what-we-do">Altitude Tent Package</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/who-we-are">Altitude Training Mask Package</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/who-we-are">Altitude Canopy Package</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="align-self-center">
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>
                  {" "}
                  <Link to="rentals">Rentals</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d-flex flex-column">
                  <Dropdown.Item>
                    <Link to="/what-we-do">Altitude Mask Rentals</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Altitude Tent Rentals</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Chamber Rentals</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="align-self-center">
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>
                  {" "}
                  <Link to="#about-us">Chambers & Rooms</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d-flex flex-column">
                  <Dropdown.Item>
                    <Link to="/what-we-do">Chambers</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/who-we-are">Room Conversions</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/who-we-are">Home Oxygenation System</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Environmental Chamber</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">
                      Environmental Training for Equine
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="align-self-center">
              <Link to="/get-involved">Customize Equipment</Link>
            </li>

            <li className="align-self-center">
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>
                  {" "}
                  <Link to="rentals">Applications</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d-flex flex-column">
                  <Dropdown.Item>
                    <Link to="/what-we-do">Mountaineering</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Sports Performance</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Professional Teams</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Wellness</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Research</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Home Oxygenation</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="align-self-center">
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>
                  {" "}
                  <Link to="/">Blog</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d-flex flex-column">
                  <Dropdown.Item>
                    <Link to="/what-we-do">Blog</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/what-we-do">Resources</Link>
                  </Dropdown.Item>{" "}
                  <Dropdown.Item>
                    <Link to="/what-we-do">Work Examples</Link>
                  </Dropdown.Item>{" "}
                  <Dropdown.Item>
                    <Link to="/what-we-do">Science Articles</Link>
                  </Dropdown.Item>{" "}
                  <Dropdown.Item>
                    <Link to="/what-we-do">Blog</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="align-self-center">
              <Link to="/donate">About</Link>
            </li>
            <li className="align-self-center">
              <Link to="/donate">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HorizontalMenu
