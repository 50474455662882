import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import FooterMenu from "../footer/footerMenu"

const footer = (props) => {
  return (
    <div>
      <div className="footer text-white px-5">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-md-4 mb-3 mb-md-0 p-m-right">
              <StaticImage className="mb-5" src="../../images/logo.png" />
              <p>
                We’re a group of adventure seekers and sports fanatics that are
                passionate about showing people the benefits of altitude
                training. While we specialize in making low-oxygen, altitude
                training equipment, our passion is helping teams and individuals
                enhance their performance, recover faster and improve their
                quality of life. We’re grateful for the opportunity to work with
                professional athletes but in truth our greatest joy comes from
                helping people achieve things they never thought possible.
              </p>
            </div>
            <div className="col-md-2 mb-3 mb-md-0 ">
              <h6 className="text-uppercase mb-4">QUICK LINKS</h6>
              <FooterMenu />
            </div>
            <div className="col-md-2 mb-3 mb-md-0 ">
              <h6 className="text-uppercase mb-4">USEFUL LINKS</h6>
              <ul className="list-unstyled d-block mb-0">
                <li className="align-self-center">
                  <Link to="/">Refund and Returns Policy</Link>
                </li>
                <li className="align-self-center">
                  <Link to="/">Science</Link>
                </li>
                <li className="align-self-center">
                  <Link to="/">Altitude to Oxygen Chart</Link>
                </li>
                <li className="align-self-center">
                  <Link to="/">Altitude Rehab</Link>
                </li>
                <li className="align-self-center">
                  <Link to="/">Shop</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-3 mb-md-0">
              <h6 className="text-uppercase mb-4">CONTACT US</h6>
              <ul className="list-unstyled d-block mb-0">
                <li className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={18}
                    className="mb-1 me-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <Link to="/">info@milehightraining.com</Link>
                </li>
                <li className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    className="mb-1 me-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <Link to="/">+800-627-4510</Link>
                </li>
                <li className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    className="mb-1 me-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>{" "}
                  <Link to="/">Address Here</Link>
                </li>
              </ul>
              <h6 className="text-uppercase mt-5">SOCIAL MEDIA</h6>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="fa fa-facebook ms-0" />
                </a>
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
            <div className="col-md-2 mb-3 mb-md-0 text-center d-flex justify-content-center">
              <div>
                {" "}
                <h6 className="text-uppercase mb-4">SECURE CHECKOUT</h6>
                <StaticImage
                  className="mb-2"
                  src="../../images/secure-pay.png"
                />
                <div className="d-flex">
                  <StaticImage
                    className="mx-1"
                    src="../../images/payment-logo/visa.png"
                  />
                  <StaticImage
                    className="mx-1"
                    src="../../images/payment-logo/mastercard.png"
                  />
                  <StaticImage
                    className="mx-1"
                    src="../../images/payment-logo/divers.png"
                  />
                </div>
                <div className="d-flex">
                  <StaticImage
                    className="mx-1 mt-1"
                    src="../../images/payment-logo/amex.png"
                  />
                  <StaticImage
                    className="mx-1 mt-1"
                    src="../../images/payment-logo/discover.png"
                  />
                  <StaticImage
                    className="mx-1 mt-1"
                    src="../../images/payment-logo/jcb.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright px-5">
        <div className="container-fluid">
          <span>
            © {new Date().getFullYear()} Mile High Training, LLC. All Rights
            Reserved. Designed and powered by {``}
            <a
              href="https://webriq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebriQ
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  )
}

export default footer
